$otopod-green: #2ea56f;
$otopod-dark-green: #304646;
$otopod-yellow: #e1e34e;
$otopod-gray: #808183;
$otopod-light-gray: #bdbdbd;

$otopod-green-pal: (
  50: #ffffff,
  100: #ffffff,
  200: #ffffff,
  300: $otopod-green,
  400: $otopod-green,
  500: $otopod-green,
  600: $otopod-green,
  700: $otopod-dark-green,
  800: $otopod-dark-green,
  900: $otopod-dark-green,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$otopod-yellow-pal: (
  50: $otopod-yellow,
  100: $otopod-yellow,
  200: $otopod-yellow,
  300: $otopod-yellow,
  400: $otopod-yellow,
  500: $otopod-yellow,
  600: $otopod-yellow,
  700: $otopod-yellow,
  800: $otopod-yellow,
  900: $otopod-yellow,
  A100: $otopod-yellow,
  A200: $otopod-yellow,
  A400: $otopod-yellow,
  A700: $otopod-yellow,
  contrast: (
    50: $otopod-yellow,
    100: $otopod-yellow,
    200: $otopod-yellow,
    300: $otopod-yellow,
    400: $otopod-yellow,
    500: $otopod-yellow,
    600: $otopod-yellow,
    700: $otopod-yellow,
    800: $otopod-yellow,
    900: $otopod-yellow,
    A100: $otopod-yellow,
    A200: $otopod-yellow,
    A400: $otopod-yellow,
    A700: $otopod-yellow,
  ),
);
