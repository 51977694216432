@font-face {
  font-family: 'otopod-icons';
  src: url('otopod-icons.ttf?r382tw') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.otopod-icons {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'otopod-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.otopod-icons-activity:before {
  content: '\e91f';
}
.otopod-icons-airplay:before {
  content: '\e920';
}
.otopod-icons-alert-circle:before {
  content: '\e921';
}
.otopod-icons-alert-octagon:before {
  content: '\e922';
}
.otopod-icons-alert-triangle:before {
  content: '\e923';
}
.otopod-icons-align-center:before {
  content: '\e924';
}
.otopod-icons-align-justify:before {
  content: '\e925';
}
.otopod-icons-align-left:before {
  content: '\e926';
}
.otopod-icons-align-right:before {
  content: '\e927';
}
.otopod-icons-anchor:before {
  content: '\e928';
}
.otopod-icons-aperture:before {
  content: '\e929';
}
.otopod-icons-archive:before {
  content: '\e92a';
}
.otopod-icons-arrow-down:before {
  content: '\e92b';
}
.otopod-icons-arrow-down-circle:before {
  content: '\e92c';
}
.otopod-icons-arrow-down-left:before {
  content: '\e92d';
}
.otopod-icons-arrow-down-right:before {
  content: '\e92e';
}
.otopod-icons-arrow-left:before {
  content: '\e92f';
}
.otopod-icons-arrow-left-circle:before {
  content: '\e930';
}
.otopod-icons-arrow-right:before {
  content: '\e931';
}
.otopod-icons-arrow-right-circle:before {
  content: '\e932';
}
.otopod-icons-arrow-up:before {
  content: '\e933';
}
.otopod-icons-arrow-up-circle:before {
  content: '\e934';
}
.otopod-icons-arrow-up-left:before {
  content: '\e935';
}
.otopod-icons-arrow-up-right:before {
  content: '\e936';
}
.otopod-icons-at-sign:before {
  content: '\e937';
}
.otopod-icons-award:before {
  content: '\e938';
}
.otopod-icons-bar-chart:before {
  content: '\e939';
}
.otopod-icons-bar-chart-2:before {
  content: '\e93a';
}
.otopod-icons-battery:before {
  content: '\e93b';
}
.otopod-icons-battery-charging:before {
  content: '\e93c';
}
.otopod-icons-bell:before {
  content: '\e93d';
}
.otopod-icons-bell-off:before {
  content: '\e93e';
}
.otopod-icons-bluetooth:before {
  content: '\e93f';
}
.otopod-icons-bold:before {
  content: '\e940';
}
.otopod-icons-book:before {
  content: '\e941';
}
.otopod-icons-book-open:before {
  content: '\e942';
}
.otopod-icons-bookmark:before {
  content: '\e943';
}
.otopod-icons-box:before {
  content: '\e944';
}
.otopod-icons-briefcase:before {
  content: '\e945';
}
.otopod-icons-calendar:before {
  content: '\e946';
}
.otopod-icons-camera:before {
  content: '\e947';
}
.otopod-icons-camera-off:before {
  content: '\e948';
}
.otopod-icons-cast:before {
  content: '\e949';
}
.otopod-icons-check:before {
  content: '\e94a';
}
.otopod-icons-check-circle:before {
  content: '\e94b';
}
.otopod-icons-check-square:before {
  content: '\e94c';
}
.otopod-icons-chevron-down:before {
  content: '\e94d';
}
.otopod-icons-chevron-left:before {
  content: '\e94e';
}
.otopod-icons-chevron-right:before {
  content: '\e94f';
}
.otopod-icons-chevron-up:before {
  content: '\e950';
}
.otopod-icons-chevrons-down:before {
  content: '\e951';
}
.otopod-icons-chevrons-left:before {
  content: '\e952';
}
.otopod-icons-chevrons-right:before {
  content: '\e953';
}
.otopod-icons-chevrons-up:before {
  content: '\e954';
}
.otopod-icons-chrome:before {
  content: '\e955';
}
.otopod-icons-circle:before {
  content: '\e956';
}
.otopod-icons-clipboard:before {
  content: '\e957';
}
.otopod-icons-clock:before {
  content: '\e958';
}
.otopod-icons-cloud:before {
  content: '\e959';
}
.otopod-icons-cloud-drizzle:before {
  content: '\e95a';
}
.otopod-icons-cloud-lightning:before {
  content: '\e95b';
}
.otopod-icons-cloud-off:before {
  content: '\e95c';
}
.otopod-icons-cloud-rain:before {
  content: '\e95d';
}
.otopod-icons-cloud-snow:before {
  content: '\e95e';
}
.otopod-icons-code:before {
  content: '\e95f';
}
.otopod-icons-codepen:before {
  content: '\e960';
}
.otopod-icons-codesandbox:before {
  content: '\e961';
}
.otopod-icons-coffee:before {
  content: '\e962';
}
.otopod-icons-columns:before {
  content: '\e963';
}
.otopod-icons-command:before {
  content: '\e964';
}
.otopod-icons-compass:before {
  content: '\e965';
}
.otopod-icons-copy:before {
  content: '\e966';
}
.otopod-icons-corner-down-left:before {
  content: '\e967';
}
.otopod-icons-corner-down-right:before {
  content: '\e968';
}
.otopod-icons-corner-left-down:before {
  content: '\e969';
}
.otopod-icons-corner-left-up:before {
  content: '\e96a';
}
.otopod-icons-corner-right-down:before {
  content: '\e96b';
}
.otopod-icons-corner-right-up:before {
  content: '\e96c';
}
.otopod-icons-corner-up-left:before {
  content: '\e96d';
}
.otopod-icons-corner-up-right:before {
  content: '\e96e';
}
.otopod-icons-cpu:before {
  content: '\e96f';
}
.otopod-icons-credit-card:before {
  content: '\e970';
}
.otopod-icons-crop:before {
  content: '\e971';
}
.otopod-icons-crosshair:before {
  content: '\e972';
}
.otopod-icons-database:before {
  content: '\e973';
}
.otopod-icons-delete:before {
  content: '\e974';
}
.otopod-icons-disc:before {
  content: '\e975';
}
.otopod-icons-dollar-sign:before {
  content: '\e976';
}
.otopod-icons-download:before {
  content: '\e977';
}
.otopod-icons-download-cloud:before {
  content: '\e978';
}
.otopod-icons-droplet:before {
  content: '\e979';
}
.otopod-icons-edit:before {
  content: '\e97a';
}
.otopod-icons-edit-2:before {
  content: '\e97b';
}
.otopod-icons-edit-3:before {
  content: '\e97c';
}
.otopod-icons-external-link:before {
  content: '\e97d';
}
.otopod-icons-eye:before {
  content: '\e97e';
}
.otopod-icons-eye-off:before {
  content: '\e97f';
}
.otopod-icons-facebook:before {
  content: '\e980';
}
.otopod-icons-fast-forward:before {
  content: '\e981';
}
.otopod-icons-feather:before {
  content: '\e982';
}
.otopod-icons-figma:before {
  content: '\e983';
}
.otopod-icons-file:before {
  content: '\e984';
}
.otopod-icons-file-minus:before {
  content: '\e985';
}
.otopod-icons-file-plus:before {
  content: '\e986';
}
.otopod-icons-file-text:before {
  content: '\e987';
}
.otopod-icons-film:before {
  content: '\e988';
}
.otopod-icons-filter:before {
  content: '\e989';
}
.otopod-icons-flag:before {
  content: '\e98a';
}
.otopod-icons-folder:before {
  content: '\e98b';
}
.otopod-icons-folder-minus:before {
  content: '\e98c';
}
.otopod-icons-folder-plus:before {
  content: '\e98d';
}
.otopod-icons-framer:before {
  content: '\e98e';
}
.otopod-icons-frown:before {
  content: '\e98f';
}
.otopod-icons-gift:before {
  content: '\e990';
}
.otopod-icons-git-branch:before {
  content: '\e991';
}
.otopod-icons-git-commit:before {
  content: '\e992';
}
.otopod-icons-git-merge:before {
  content: '\e993';
}
.otopod-icons-git-pull-request:before {
  content: '\e994';
}
.otopod-icons-github:before {
  content: '\e995';
}
.otopod-icons-gitlab:before {
  content: '\e996';
}
.otopod-icons-globe:before {
  content: '\e997';
}
.otopod-icons-grid:before {
  content: '\e998';
}
.otopod-icons-hard-drive:before {
  content: '\e999';
}
.otopod-icons-hash:before {
  content: '\e99a';
}
.otopod-icons-headphones:before {
  content: '\e99b';
}
.otopod-icons-heart:before {
  content: '\e99c';
}
.otopod-icons-help-circle:before {
  content: '\e99d';
}
.otopod-icons-hexagon:before {
  content: '\e99e';
}
.otopod-icons-home:before {
  content: '\e99f';
}
.otopod-icons-image:before {
  content: '\e9a0';
}
.otopod-icons-inbox:before {
  content: '\e9a1';
}
.otopod-icons-info:before {
  content: '\e9a2';
}
.otopod-icons-instagram:before {
  content: '\e9a3';
}
.otopod-icons-italic:before {
  content: '\e9a4';
}
.otopod-icons-key:before {
  content: '\e9a5';
}
.otopod-icons-layers:before {
  content: '\e9a6';
}
.otopod-icons-layout:before {
  content: '\e9a7';
}
.otopod-icons-life-buoy:before {
  content: '\e9a8';
}
.otopod-icons-link:before {
  content: '\e9a9';
}
.otopod-icons-link-2:before {
  content: '\e9aa';
}
.otopod-icons-linkedin:before {
  content: '\e9ab';
}
.otopod-icons-list:before {
  content: '\e9ac';
}
.otopod-icons-loader:before {
  content: '\e9ad';
}
.otopod-icons-lock:before {
  content: '\e9ae';
}
.otopod-icons-log-in:before {
  content: '\e9af';
}
.otopod-icons-log-out:before {
  content: '\e9b0';
}
.otopod-icons-mail:before {
  content: '\e9b1';
}
.otopod-icons-map:before {
  content: '\e9b2';
}
.otopod-icons-map-pin:before {
  content: '\e9b3';
}
.otopod-icons-maximize:before {
  content: '\e9b4';
}
.otopod-icons-maximize-2:before {
  content: '\e9b5';
}
.otopod-icons-meh:before {
  content: '\e9b6';
}
.otopod-icons-menu:before {
  content: '\e9b7';
}
.otopod-icons-message-circle:before {
  content: '\e9b8';
}
.otopod-icons-message-square:before {
  content: '\e9b9';
}
.otopod-icons-mic:before {
  content: '\e9ba';
}
.otopod-icons-mic-off:before {
  content: '\e9bb';
}
.otopod-icons-minimize:before {
  content: '\e9bc';
}
.otopod-icons-minimize-2:before {
  content: '\e9bd';
}
.otopod-icons-minus:before {
  content: '\e9be';
}
.otopod-icons-minus-circle:before {
  content: '\e9bf';
}
.otopod-icons-minus-square:before {
  content: '\e9c0';
}
.otopod-icons-monitor:before {
  content: '\e9c1';
}
.otopod-icons-moon:before {
  content: '\e9c2';
}
.otopod-icons-more-horizontal:before {
  content: '\e9c3';
}
.otopod-icons-more-vertical:before {
  content: '\e9c4';
}
.otopod-icons-mouse-pointer:before {
  content: '\e9c5';
}
.otopod-icons-move:before {
  content: '\e9c6';
}
.otopod-icons-music:before {
  content: '\e9c7';
}
.otopod-icons-navigation:before {
  content: '\e9c8';
}
.otopod-icons-navigation-2:before {
  content: '\e9c9';
}
.otopod-icons-octagon:before {
  content: '\e9ca';
}
.otopod-icons-package:before {
  content: '\e9cb';
}
.otopod-icons-paperclip:before {
  content: '\e9cc';
}
.otopod-icons-pause1:before {
  content: '\e9cd';
}
.otopod-icons-pause-circle:before {
  content: '\e9ce';
}
.otopod-icons-pen-tool:before {
  content: '\e9cf';
}
.otopod-icons-percent:before {
  content: '\e9d0';
}
.otopod-icons-phone:before {
  content: '\e9d1';
}
.otopod-icons-phone-call:before {
  content: '\e9d2';
}
.otopod-icons-phone-forwarded:before {
  content: '\e9d3';
}
.otopod-icons-phone-incoming:before {
  content: '\e9d4';
}
.otopod-icons-phone-missed:before {
  content: '\e9d5';
}
.otopod-icons-phone-off:before {
  content: '\e9d6';
}
.otopod-icons-phone-outgoing:before {
  content: '\e9d7';
}
.otopod-icons-pie-chart:before {
  content: '\e9d8';
}
.otopod-icons-play1:before {
  content: '\e9d9';
}
.otopod-icons-play-circle:before {
  content: '\e9da';
}
.otopod-icons-plus:before {
  content: '\e9db';
}
.otopod-icons-plus-circle:before {
  content: '\e9dc';
}
.otopod-icons-plus-square:before {
  content: '\e9dd';
}
.otopod-icons-pocket:before {
  content: '\e9de';
}
.otopod-icons-power:before {
  content: '\e9df';
}
.otopod-icons-printer:before {
  content: '\e9e0';
}
.otopod-icons-radio:before {
  content: '\e9e1';
}
.otopod-icons-refresh-ccw:before {
  content: '\e9e2';
}
.otopod-icons-refresh-cw:before {
  content: '\e9e3';
}
.otopod-icons-repeat:before {
  content: '\e9e4';
}
.otopod-icons-rewind:before {
  content: '\e9e5';
}
.otopod-icons-rotate-ccw:before {
  content: '\e9e6';
}
.otopod-icons-rotate-cw:before {
  content: '\e9e7';
}
.otopod-icons-rss:before {
  content: '\e9e8';
}
.otopod-icons-save:before {
  content: '\e9e9';
}
.otopod-icons-scissors:before {
  content: '\e9ea';
}
.otopod-icons-search:before {
  content: '\e9eb';
}
.otopod-icons-send:before {
  content: '\e9ec';
}
.otopod-icons-server:before {
  content: '\e9ed';
}
.otopod-icons-settings:before {
  content: '\e9ee';
}
.otopod-icons-share:before {
  content: '\e9ef';
}
.otopod-icons-share-2:before {
  content: '\e9f0';
}
.otopod-icons-shield:before {
  content: '\e9f1';
}
.otopod-icons-shield-off:before {
  content: '\e9f2';
}
.otopod-icons-shopping-bag:before {
  content: '\e9f3';
}
.otopod-icons-shopping-cart:before {
  content: '\e9f4';
}
.otopod-icons-shuffle:before {
  content: '\e9f5';
}
.otopod-icons-sidebar:before {
  content: '\e9f6';
}
.otopod-icons-skip-back:before {
  content: '\e9f7';
}
.otopod-icons-skip-forward:before {
  content: '\e9f8';
}
.otopod-icons-slack:before {
  content: '\e9f9';
}
.otopod-icons-slash:before {
  content: '\e9fa';
}
.otopod-icons-sliders:before {
  content: '\e9fb';
}
.otopod-icons-smartphone:before {
  content: '\e9fc';
}
.otopod-icons-smile:before {
  content: '\e9fd';
}
.otopod-icons-speaker:before {
  content: '\e9fe';
}
.otopod-icons-square:before {
  content: '\e9ff';
}
.otopod-icons-star:before {
  content: '\ea00';
}
.otopod-icons-stop-circle:before {
  content: '\ea01';
}
.otopod-icons-sun:before {
  content: '\ea02';
}
.otopod-icons-sunrise:before {
  content: '\ea03';
}
.otopod-icons-sunset:before {
  content: '\ea04';
}
.otopod-icons-tablet:before {
  content: '\ea05';
}
.otopod-icons-tag:before {
  content: '\ea06';
}
.otopod-icons-target:before {
  content: '\ea07';
}
.otopod-icons-terminal:before {
  content: '\ea08';
}
.otopod-icons-thermometer:before {
  content: '\ea09';
}
.otopod-icons-thumbs-down:before {
  content: '\ea0a';
}
.otopod-icons-thumbs-up:before {
  content: '\ea0b';
}
.otopod-icons-toggle-left:before {
  content: '\ea0c';
}
.otopod-icons-toggle-right:before {
  content: '\ea0d';
}
.otopod-icons-tool:before {
  content: '\ea0e';
}
.otopod-icons-trash:before {
  content: '\ea0f';
}
.otopod-icons-G-2:before {
  content: '\ea10';
}
.otopod-icons-trello:before {
  content: '\ea11';
}
.otopod-icons-trending-down:before {
  content: '\ea12';
}
.otopod-icons-trending-up:before {
  content: '\ea13';
}
.otopod-icons-triangle:before {
  content: '\ea14';
}
.otopod-icons-truck:before {
  content: '\ea15';
}
.otopod-icons-tv:before {
  content: '\ea16';
}
.otopod-icons-twitch:before {
  content: '\ea17';
}
.otopod-icons-twitter:before {
  content: '\ea18';
}
.otopod-icons-type:before {
  content: '\ea19';
}
.otopod-icons-umbrella:before {
  content: '\ea1a';
}
.otopod-icons-underline:before {
  content: '\ea1b';
}
.otopod-icons-unlock:before {
  content: '\ea1c';
}
.otopod-icons-upload:before {
  content: '\ea1d';
}
.otopod-icons-upload-cloud:before {
  content: '\ea1e';
}
.otopod-icons-user:before {
  content: '\ea1f';
}
.otopod-icons-user-check:before {
  content: '\ea20';
}
.otopod-icons-user-minus:before {
  content: '\ea21';
}
.otopod-icons-user-plus:before {
  content: '\ea22';
}
.otopod-icons-user-x:before {
  content: '\ea23';
}
.otopod-icons-users:before {
  content: '\ea24';
}
.otopod-icons-video:before {
  content: '\ea25';
}
.otopod-icons-video-off:before {
  content: '\ea26';
}
.otopod-icons-voicemail:before {
  content: '\ea27';
}
.otopod-icons-volume:before {
  content: '\ea28';
}
.otopod-icons-volume-1:before {
  content: '\ea29';
}
.otopod-icons-volume-2:before {
  content: '\ea2a';
}
.otopod-icons-volume-x:before {
  content: '\ea2b';
}
.otopod-icons-watch:before {
  content: '\ea2c';
}
.otopod-icons-wifi:before {
  content: '\ea2d';
}
.otopod-icons-wifi-off:before {
  content: '\ea2e';
}
.otopod-icons-wind:before {
  content: '\ea2f';
}
.otopod-icons-x:before {
  content: '\ea30';
}
.otopod-icons-x-circle:before {
  content: '\ea31';
}
.otopod-icons-x-octagon:before {
  content: '\ea32';
}
.otopod-icons-x-square:before {
  content: '\ea33';
}
.otopod-icons-youtube:before {
  content: '\ea34';
}
.otopod-icons-zap:before {
  content: '\ea35';
}
.otopod-icons-zap-off:before {
  content: '\ea36';
}
.otopod-icons-zoom-in:before {
  content: '\ea37';
}
.otopod-icons-zoom-out:before {
  content: '\ea38';
}
.otopod-icons-start_light:before {
  content: '\e900';
  color: #01a870;
}
.otopod-icons-start_dark:before {
  content: '\e901';
  color: #01a870;
}
.otopod-icons-search_light:before {
  content: '\e902';
  color: #01a870;
}
.otopod-icons-search_darl:before {
  content: '\e903';
  color: #01a870;
}
.otopod-icons-profile_light:before {
  content: '\e905';
  color: #01a870;
}
.otopod-icons-profile_dark:before {
  content: '\e906';
  color: #01a870;
}
.otopod-icons-prev:before {
  content: '\e907';
  color: #cbcac8;
}
.otopod-icons-play .path1:before {
  content: '\e908';
  color: rgb(1, 168, 112);
}
.otopod-icons-play .path2:before {
  content: '\e909';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.otopod-icons-pause .path1:before {
  content: '\e90a';
  color: rgb(1, 168, 112);
}
.otopod-icons-pause .path2:before {
  content: '\e90b';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.otopod-icons-pause .path3:before {
  content: '\e90c';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.otopod-icons-next:before {
  content: '\e90d';
  color: #cbcac8;
}
.otopod-icons-mail_light:before {
  content: '\e90e';
  color: #01a870;
}
.otopod-icons-mail_dark .path1:before {
  content: '\e910';
  color: rgb(1, 168, 112);
}
.otopod-icons-mail_dark .path2:before {
  content: '\e911';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.otopod-icons-logo:before {
  content: '\e912';
  color: #00a76d;
}
.otopod-icons-home_light:before {
  content: '\e913';
  color: #01a870;
}
.otopod-icons-home_dark:before {
  content: '\e914';
  color: #01a870;
}
.otopod-icons-help .path1:before {
  content: '\e915';
  color: rgb(1, 168, 112);
}
.otopod-icons-help .path2:before {
  content: '\e916';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.otopod-icons-help .path3:before {
  content: '\e917';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.otopod-icons-heart_light:before {
  content: '\e918';
  color: #01a870;
}
.otopod-icons-heart_dark:before {
  content: '\e919';
  color: #01a870;
}
.otopod-icons-chat_light:before {
  content: '\e91a';
  color: #01a870;
}
.otopod-icons-chat_dark .path1:before {
  content: '\e91b';
  color: rgb(1, 168, 112);
}
.otopod-icons-chat_dark .path2:before {
  content: '\e91c';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.otopod-icons-chat_dark .path3:before {
  content: '\e91d';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.otopod-icons-chat_dark .path4:before {
  content: '\e91e';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
