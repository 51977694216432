@use '@angular/material' as mat;
@import '@angular/flex-layout/mq';
@import 'variables';
@import 'premium-banner';

@font-face {
  font-family: 'Nunito';
  src: url('../../assets/NunitoSans-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('../../assets/NunitoSans-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: 'CoText';
  src: url('../../assets/CoText_Rg.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'CoText';
  src: url('../../assets/CoText_Bd.ttf');
  font-weight: bold;
}

$otopod-typography: mat.define-legacy-typography-config(
  $font-family: 'Nunito, sans-serif',
  $display-4: mat.define-typography-level(112px, 112px, 300, 'CoText', -0.05rem),
  $display-3: mat.define-typography-level(56px, 56px, 400, 'CoText', -0.02rem),
  $display-2: mat.define-typography-level(45px, 48px, 400, 'CoText', -0.005em),
  $display-1: mat.define-typography-level(34px, 40px, 400, 'CoText'),
  $headline: mat.define-typography-level(45px, 45px, bold, 'CoText'),
  $title: mat.define-typography-level(32px, 32px, 500, 'CoText'),
  $subheading-2: mat.define-typography-level(16px, 28px, bold),
  $subheading-1: mat.define-typography-level(15px, 24px, 400),
  $body-2: mat.define-typography-level(16px, 24px, 500),
  $body-1: mat.define-typography-level(16px, 24px, 400),
  $caption: mat.define-typography-level(12px, 20px, 400),
);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($otopod-typography);`
@include mat.all-legacy-component-typographies($otopod-typography);
@include mat.legacy-core();

$primary: mat.define-palette($otopod-green-pal);
$accent: mat.define-palette($otopod-yellow-pal);
$warn: mat.define-palette(mat.$red-palette);
$light-theme: mat.define-light-theme($primary, $accent, $warn);

@function otopod-light-theme-foreground($color) {
  @return (
    base: $color,
    divider: rgba(black, 0.12),
    dividers: rgba(black, 0.12),
    disabled: rgba($color, 0.38),
    disabled-button: rgba($color, 0.38),
    disabled-text: rgba($color, 0.38),
    hint-text: rgba($color, 0.38),
    secondary-text: rgba($color, 0.54),
    icon: rgba($color, 0.54),
    icons: rgba($color, 0.54),
    text: $color,
    slider-off: rgba($color, 0.26),
    slider-off-active: rgba($color, 0.38),
    slider-min: rgba($color, 0.38)
  );
}

$otopod-light-theme: map-merge(
  $light-theme,
  (
    foreground: otopod-light-theme-foreground($otopod-dark-green),
  )
);
$otopod-dark-theme: mat.define-dark-theme($primary, $accent, $warn);

.color-scheme-light {
  @include mat.all-legacy-component-themes($otopod-light-theme);
}

.color-scheme-dark {
  @include mat.all-legacy-component-themes($otopod-dark-theme);
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  color: $otopod-dark-green;
}

.mat-button,
.mat-flat-button,
.mat-icon-button,
.mat-stroked-button,
.mat-raised-button {
  border-radius: 100px !important;
}

.otopod-caption {
  color: $otopod-gray;
}

.responsive-container {
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;

  @include layout-bp(gt-xs) {
    max-width: 600px;
  }

  @include layout-bp(gt-sm) {
    max-width: 960px;
  }

  @include layout-bp(gt-md) {
    max-width: 1280px;
  }

  @include layout-bp(gt-lg) {
    max-width: 1536px;
  }
}

a {
  color: $otopod-green;
}

.primary-button {
  font-family: 'CoText';
}
