.premium-banner {
  --d: 6px;
  --f: 16px;
  --top: 0px;
  --left: 0px;

  z-index: 1;
  position: relative;
  display: inline-block;
}

.premium-banner::before {
  content: attr(premium-banner-text);
  position: absolute;
  font-size: var(--f);
  top: var(--top);
  left: var(--left);
  right: auto;
  transform: translate(-29.29%, -100%) rotate(-45deg);
  text-align: center;
  border: 1px solid transparent;
  border-bottom: 0;
  transform-origin: bottom right;
  padding: 5px 35px calc(var(--d) + 5px);
  background: linear-gradient(rgba(0, 0, 0, 0.5) 0 0) bottom/100% var(--d) no-repeat $otopod-yellow;
  background-clip: padding-box;
  clip-path: polygon(
    0 0,
    100% 0,
    100% 100%,
    calc(100% - var(--d)) calc(100% - var(--d)),
    var(--d) calc(100% - var(--d)),
    0 100%
  );
  -webkit-mask: linear-gradient(135deg, transparent calc(50% - var(--d) * 0.707), #fff 0) bottom left,
    linear-gradient(-135deg, transparent calc(50% - var(--d) * 0.707), #fff 0) bottom right;
  -webkit-mask-size: 300vmax 300vmax;
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
}
